import React from 'react';
import Carousel from '@itseasy21/react-elastic-carousel';
import { Card } from '../Cards/CollectionCard';
import { useRouter } from 'next/router';
import { Box } from '@chakra-ui/react';

const ArkCarousel = Carousel as any;

const breakPoints = [
	{ width: 1, itemsToShow: 2, showArrows: true },
	{ width: 550, itemsToShow: 3, showArrows: true },
	{ width: 850, itemsToShow: 4, showArrows: true },
];

const CarouselCollection: React.FC<any> = ({ collection, isCreator, descriptionLength, isDescription, cardWidth, isAvatar }) => {
	const router = useRouter();
	return (
		<ArkCarousel isRTL={false} breakPoints={breakPoints} itemPadding={[0, 10]}>
			{collection.map((item: any) => (
				<Box h={'100%'} mx={0} pb={5} key={item?.id}>
					<Card
						item={item}
						isDescription={isDescription}
						isCreator={isCreator}
						descriptionLength={descriptionLength}
						cardWidth={cardWidth}
						callback={() => {
							router.push(`/collection/${item.id}`);
						}}
						isAvatar={isAvatar}
						tokenId={item.tokenId}
					/>
				</Box>
			))}
		</ArkCarousel>
	);
};

export default CarouselCollection;
